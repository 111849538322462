import * as React from "react";
import LetUsGuideYouImage from "../../images/let-us-guide-you.svg";
import {
  LetUsGuideYouContainer,
  LetUsGuideYouImageContainer,
  Container,
  CardContainer,
  StyledButton,
  StyledHeading,
  StyledText,
  StyledAnchor,
} from "./LetUsGuideYou.styles";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

const LetUsGuideYou = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <LetUsGuideYouContainer id="#contact-us">
      {isMobile && (
        <StyledHeading weight="semiBold">Let us guide you</StyledHeading>
      )}
      <LetUsGuideYouImageContainer
        src={LetUsGuideYouImage}
        alt="let-us-guide-you"
      />

      <Container>
        {!isMobile && (
          <StyledHeading weight="semiBold">Let us guide you</StyledHeading>
        )}
        <CardContainer>
          <StyledText>
            For founders on their first rodeo and facing challenges with their
            product or engineering or culture or working with each other, our
            advisors can provide the experienced guidance you need. Think of our
            advisors like a startup therapist – experienced in providing
            direction and reliable support throughout your journey.
          </StyledText>
          <StyledText>
            Whether it’s honing in on product development or determining how to
            effectively work with your cofounder, our advisors provide an
            objective and direct approach that can help founders make better
            decisions as they progress. With a cumulative 30+ years of
            experience working across both D2C and enterprise SAAS companies, we
            are confident we've already crossed the bridge you are standing in
            front of.
          </StyledText>

          <StyledAnchor
            target="_blank"
            href="mailto:hello@alphathesis.co"
          >
            <StyledButton
              label="Get in touch"
              variant="primary"
              weight="semiBold"
            />
          </StyledAnchor>
        </CardContainer>
      </Container>
    </LetUsGuideYouContainer>
  );
};

export default LetUsGuideYou;
