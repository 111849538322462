import * as React from "react";
import Layout from "../components/Layout/Layout";

import Footer from "../components/Footer";
import ScrollHandler from "../components/ScrollHander";
import FAQ from "../components/FAQ";
import LetUsGuideYou from "../components/LetUsGuideYou/LetUsGuideYou";

const SupportPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <LetUsGuideYou />
      <FAQ />
      <Footer />
    </Layout>
  </>
);

export default SupportPage;
