import { useStaticQuery, graphql } from "gatsby";

const useClientLogoQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulClientLogos {
        nodes {
          id
          linkedInCompanyLogoLink
        }
      }
    }
  `);
  return data.allContentfulClientLogos.nodes;
};

export default useClientLogoQuery;
