import * as React from "react";
import {
  FAQContainer,
  ItemsContainer,
  CardContainer,
  StyledCard,
  Subtitle,
  MobileHeading,
  AnswerContainer,
  AnswerContent,
} from "./FAQ.styles";
import { Heading, Paragraph } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";
import { useFaqQuery } from "../../hooks";

const renderNode = (node) => {
  switch (node.nodeType) {
    case "document":
      // Recursively render the document content
      return node.content.map((childNode, index) => (
        <div key={index}>{renderNode(childNode)}</div>
      ));
    case "paragraph":
    case "list-item":
      // Render paragraphs or List Items
      return (
        <Paragraph key={node.content.map((child) => child.value).join("")}>
          {node.content.map((childNode, index) => renderNode(childNode))}
        </Paragraph>
      );
    case "ordered-list":
      // Render ordered lists
      return (
        <ol key={node.content.map((child) => child.value).join("")}>
          {node.content.map((childNode, index) => (
            <li key={index}>{renderNode(childNode)}</li>
          ))}
        </ol>
      );
    case "unordered-list":
      // Render unordered lists
      return (
        <ul key={node.content.map((child) => child.value).join("")}>
          {node.content.map((childNode, index) => (
            <li key={index}>{renderNode(childNode)}</li>
          ))}
        </ul>
      );
    case "text":
      // Render text nodes
      let content = node.value;

      // Apply marks
      if (node.marks) {
        node.marks.forEach((mark) => {
          switch (mark.type) {
            case "bold":
              content = <Paragraph weight="semiBold">{content}</Paragraph>;
              break;
            case "italic":
              content = (
                <Paragraph>
                  <em>{content}</em>
                </Paragraph>
              );
              break;
            case "underline":
              content = (
                <Paragraph>
                  <u>{content}</u>
                </Paragraph>
              );
              break;
            case "code":
              content = (
                <Paragraph>
                  <code>{content}</code>
                </Paragraph>
              );
              break;
            default:
              break;
          }
        });
      }
      return content;
    default:
      return null;
  }
};

const FAQ = () => {
  const isMobile = useMediaQuery(size.tabletL);
  const faqs = useFaqQuery();

  return (
    <FAQContainer id="#faq">
      {isMobile && (
        <MobileHeading weight="semiBold">
          Frequently Asked Questions
        </MobileHeading>
      )}
      <ItemsContainer>
        {!isMobile && (
          <Heading weight="semiBold">Frequently Asked Questions</Heading>
        )}
        <CardContainer>
          {faqs.map((node, index: number) => (
            <StyledCard key={index}>
              <Subtitle weight="semiBold">Q. {node.question}</Subtitle>
              <AnswerContainer>
                <Paragraph weight="semiBold">A.</Paragraph>{" "}
                <AnswerContent>
                  {node && renderNode(JSON.parse(node.answer.raw))}
                </AnswerContent>
              </AnswerContainer>
            </StyledCard>
          ))}
        </CardContainer>
      </ItemsContainer>
    </FAQContainer>
  );
};

export default FAQ;
