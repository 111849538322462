import { useStaticQuery, graphql } from "gatsby";

const useFaqQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaQs {
        nodes {
          question
          answer {
            raw
          }
        }
      }
    }
  `);
  return data.allContentfulFaQs.nodes;
};

export default useFaqQuery;
