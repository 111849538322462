import { useStaticQuery, graphql } from "gatsby";

const useFemaleFounderQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestimonial(filter: { gender: { eq: "Female" } }) {
        nodes {
          id
          companyName
          fullName
          title
          linkedInCompanyLogoLink
          linkedInProfileImageLink
          testimonial {
            testimonial
          }
        }
      }
    }
  `);
  return data.allContentfulTestimonial.nodes;
};

export default useFemaleFounderQuery;
