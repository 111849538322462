import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Heading, Paragraph } from "../Typography/Typography";

export const LetUsGuideYouContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 90px 0;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
  }
`;

export const StyledHeading = styled(Heading)`
  @media ${device.tabletL} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const LetUsGuideYouImageContainer = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 572px;

  @media ${device.tabletL} {
    height: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const StyledText = styled(Paragraph)`
  @media ${device.tabletL} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const StyledAnchor = styled.a`
  @media ${device.tabletL} {
    margin-top: 12px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
  @media ${device.tabletL} {
    margin-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 142px;
    padding: 10px 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
